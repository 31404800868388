import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import CardContent from '@material-ui/core/CardContent';
import validateMyPassword from '../../functions/validations/validateMyPassword';
import { reqChangePassword, reqQuickVerify } from '../../functions/requests/users';
import { redirectLogin, handleError, removeMessages, disableRequest } from '../../functions/global';

const MyPassword = () => {
    const [state, setState] = useState({
        access: false,
        password: "",
        repeatPassword: "",
        errors: {},
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false
    });

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(resQuickVerify.data.success){
                    setState(prevState => ({ ...prevState, access: true }));
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '180px',
            height: '45px',
            color: "#fff",
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        inputStyle: {            
            [theme.breakpoints.up('xs')]: { width: '90%' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '60%' },
        }
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateMyPassword(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resChangePassword = await reqChangePassword(state.password);
                    if(resChangePassword.data.success){
                        setState(prevState => ({
                            ...prevState,
                            password: "",
                            repeatPassword: "",
                            requestSuccess: true,
                            requestSuccessMessage: 'Password successfully changed.', 
                            disabledRequest: false 
                        }));
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.access &&
                <section className="verticalAlign">
                    <div className="container">
                        <div className="col-lg-12 py-4">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-xs-12">
                                    <Card variant="outlined">
                                        <CardContent>
                                            <div className="row pt-4 pb-2">
                                                <div className="col-lg-12 text-center">
                                                    <Typography variant="h4">
                                                        Change your password
                                                    </Typography>
                                                </div>
                                            </div>
                                            {state.requestError &&
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="error">{state.requestErrorMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            {state.requestSuccess &&
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="success">{state.requestSuccessMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row py-3">
                                                <div className="col-lg-12 text-center">
                                                    <form onSubmit={(e) => handleSubmit(e)}>
                                                        <div className="row py-1">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="password" label="New Password" value={state.password} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                        {state.errors.password && 
                                                                            <small className="errorSmall">{state.errors.password}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row py-1">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="repeatPassword" label="Repeat Password" value={state.repeatPassword} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                        {state.errors.repeatPassword && 
                                                                            <small className="errorSmall">{state.errors.repeatPassword}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-5 pb-2">
                                                            <div className="col-lg-12 text-center">
                                                                <Button type="submit" className={classes.buttonStyle}>CHANGE PASSWORD</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>      
                    </div>
                </section>
            }
        </React.Fragment>
    )
}

export default MyPassword;