import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import validateUploadCSV from '../../functions/validations/validateUploadCSV';
import { reqFoodFileUpload } from '../../functions/requests/foods';
import { handleError, removeMessages, disableRequest } from '../../functions/global';

const UploadCSV = ({ classes }) => {
    const [state, setState] = useState({
        file: "",
        fileName: "",
        errors: {},
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false   
    });

    const handleSubmitUpload = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateUploadCSV(noErrors);
        setState(prevState => ({ ...prevState, errors: results }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resFoodFileUpload = await reqFoodFileUpload(state.file);
                    if(resFoodFileUpload.data.success){
                        setState(prevState => ({ 
                            ...prevState, 
                            errors: {},
                            file: "",
                            fileName: "",
                            requestSuccess: true, 
                            requestSuccessMessage: 'File uploaded successfully. ' + resFoodFileUpload.data.foodCount + ' foods added.', 
                            disabledRequest: false,
                            requestError: false
                        }));
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else if(resFoodFileUpload.data.validation){
                        var newErrors = state.errors;
                        newErrors.file = resFoodFileUpload.data.validation;
                        setState(prevState => ({ 
                            ...prevState, 
                            errors: newErrors,
                            disabledRequest: true,
                            requestError: false,
                            requestSuccess: false, 
                        }));
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        if(e.target.files){
            setState(prevState => ({ ...prevState, file: e.target.files[0], fileName: e.target.files[0].name, disabledRequest: false, errors: {} }));    
        }
    }

    return (
        <React.Fragment>
            <Divider className="mt-4 mb-5" light />  
            <Typography className="ml-3" variant="h5" component="h2">
                Import CSV
            </Typography>
            <Typography className="ml-3 grayMediumLabel" variant="subtitle2">
                Headers: name, portion, calories, carbs, protein, fats, visibility (1: public, 2: private)
            </Typography>
            <Typography className="ml-3 mt-1 grayMediumLabel" variant="subtitle2">
                Maximum upload file size: 1MB
            </Typography>
            {state.requestError &&
                <div className="row pt-4 pb-1">
                    <div className="col-lg-12 text-center">
                        <Alert severity="error">{state.requestErrorMessage}</Alert>
                    </div>
                </div>
            }
            {state.requestSuccess &&
                <div className="row pt-4 pb-1">
                    <div className="col-lg-12 text-center">
                        <Alert severity="success">{state.requestSuccessMessage}</Alert>
                    </div>
                </div>
            }
            <form onSubmit={(e) => handleSubmitUpload(e)}>
                <div className="row pt-3 pb-4">
                    <div className="col-lg-12 text-center">
                        <div className="row pb-1">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12 text-left ml-3">    
                                        <div className="row">                                                                                                                  
                                        <Button
                                        className={classes.uploadButtonStyle}
                                        variant="contained"
                                        component="label"
                                        >
                                        Choose File
                                        <input type="file" name="file" onChange={(e) => handleChange(e)} style={{ display: "none" }} />
                                        </Button>
                                        <Typography className="mt-2 ml-2" variant="subtitle2">{state.fileName}</Typography>
                                        </div>      
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        {state.errors.file && 
                                            <small className="float-left ml-3 mt-2 errorSmall">{state.errors.file}</small>
                                        }
                                    </div>
                                </div>
                            </div>                                                                        
                        </div>                                       
                        <div className="row pt-5">
                            <div className="col-lg-12 text-center">
                                <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>UPLOAD</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>            
        </React.Fragment>                 
    )
}

export default UploadCSV
