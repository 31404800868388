import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../images/logo.PNG';
import LogoWhite from '../../images/logo-white.png';
import { isMobileOnly } from "react-device-detect";
import Sidebar from './sidebar';

const Header = ({path, token}) => {
    const drawerWidth = 200;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            marginBottom: 50
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            fontSize: 34,
            fontWeight: 500,
            color: '#37a37c',
            letterSpacing: 0
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: 'white',
            color: 'black'
        },
        appBarTransparent: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'black'
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
            }),
        },
        hide: {
            display: 'none',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        transparentButton: {
            backgroundColor: "#fff",
            '&:hover': {
                backgroundColor: "#fff",
            },
            minWidth: '95px',
            borderRadius: '18px',
            boxShadow: (token && currentScrollY > 50) ? 'none' : '0 0 25px rgba(16,30,54,.15)',
            marginLeft: '10px',
            marginRight: '0px',
            [theme.breakpoints.up('xs')]: { fontSize: '0.75rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '0.875rem!important' }
        }
    }));

    const [currentScrollY, setCurrentScrollY] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          setCurrentScrollY(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [currentScrollY]);

    const handleDrawerOpen = () => {
        setOpen(true);
        if(isMobileOnly){
            setShowToolbarContent(false);
        }
    };

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [showToolbarContent, setShowToolbarContent] = useState(true);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx((token && currentScrollY > 50) ? classes.appBar : classes.appBarTransparent, {
            [classes.appBarShift]: open,
            })}>
                <Toolbar>
                    {token &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    {showToolbarContent &&
                        <React.Fragment>
                            <Typography variant="h6" className={classes.title}>
                                <a href={token ? '/dailydiet' : '/home'}><img className="logo" src={((path === '/' || path === '/home') && !token) ? LogoWhite : Logo}/></a>
                            </Typography>
                            {(!token && (path === '/login' || path === '/' || path === '/home')) ? (
                                <Button className={classes.transparentButton} href="/signup">Sign Up</Button>
                            ): (!token && path === '/signup') ? (
                                <Button className={classes.transparentButton} href="/login">Log In</Button>
                            ): (!token && (path === '/forgotpassword' || path.startsWith('/verifyemail') || path.startsWith('/recoverpassword'))) ? (
                                <React.Fragment>
                                    {!isMobileOnly &&
                                        <Button className={classes.transparentButton} href="/signup">Sign Up</Button>
                                    }
                                    <Button className={classes.transparentButton} href="/login">Log In</Button>
                                </React.Fragment>
                            ): (token && !(isMobileOnly && open)) ? ( 
                                <Button className={classes.transparentButton} color="inherit" href="/logout">Log Out</Button>
                            ): (null)
                            }
                        </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            {token &&
                <Sidebar theme={theme} open={open} setOpen={setOpen} drawerWidth={drawerWidth} handleDrawerOpen={handleDrawerOpen} setShowToolbarContent={setShowToolbarContent} />
            }
        </div>
    );
}

export default Header;
