import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Calendar as CalendarComponent, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { isMobileOnly } from "react-device-detect";
import DetailModal from './detailModal';
import { reqLoadDiariesByMonth } from '../../functions/requests/diaries';
import { redirectLogin, convertToFixed } from '../../functions/global';

const Calendar = () => {
    const [state, setState] = useState({
        access: false,
        events: [],
        event: {},
        errors: {}
    });

    const [modal, setModal] = useState(false);

    useEffect(() => {
        (async () => {
            try{
                const date = moment().format('YYYY-MM');
                const dates = getStartAndEndOfMonth(date);
                getDiaries(dates.start, dates.end);
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    moment.locale('ko', {
        week: {
            dow: 1,
            doy: 1,
        },
    });

    const localizer = momentLocalizer(moment);

    const handleChange = (e) => {       
        var date = e.toString().split(' ');
        const month = moment().month(date[1]).format("M");
        date = date[3]+'-'+((month<10)?('0'+month):month);
        const dates = getStartAndEndOfMonth(date);
        getDiaries(dates.start, dates.end);
    }

    const handleOpenModal = (e) => {
        setModal(!modal);
        setState(prevState => ({ ...prevState, event: e }));
    }

    const handleCloseModal = () => {
        setModal(!modal);
    }

    const getStartAndEndOfMonth = (date) => {
        return { start: date+'-01 00:00:00', end: date+'-'+moment(date, 'YYYY-MM').daysInMonth()+' 23:59:59' };
    }

    const getDiaries = (startOfMonth, endOfMonth) => {
        (async () => {
            try{
                const resLoadDiariesByMonth = await reqLoadDiariesByMonth(startOfMonth, endOfMonth);
                if(resLoadDiariesByMonth.data.success){
                    var events = [];
                    resLoadDiariesByMonth.data.diaries.map(element => {
                        var date = element.createdAt.toString().split('-');
                        date = moment(date, "YYYY-MM-DD");
                        var startDate = moment(date).toDate().toString().split(' ');
                        events.push({
                            id: element.id, 
                            title: (isMobileOnly) ? '' : 'Calories: '+convertToFixed(element.calories/10000, 1),
                            allDay: true,
                            start: date,
                            end: date,
                            calories: element.calories,
                            carbs: element.carbs,
                            protein: element.protein,
                            fats: element.fats,
                            height: element.height,
                            weight: element.weight,
                            BMI: element.BMI,
                            BMR: element.BMR,
                            date: startDate[1]+' '+startDate[2]+' '+startDate[3]
                        });
                    });
                    setState(prevState => ({ ...prevState, events: events, access: true }));
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }

    return(
        <React.Fragment>
            {state.access &&
                 <React.Fragment>
                    <section>
                        <div className="container">
                            <div className="col-lg-12 py-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Card variant="outlined">
                                            <CardContent>
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Typography variant="h4">
                                                            Calendar
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-lg-12 text-center calendar-col">
                                                        <CalendarComponent
                                                            events={state.events}
                                                            startAccessor="start"
                                                            endAccessor="end"
                                                            defaultDate={moment().toDate()}
                                                            localizer={localizer}
                                                            views={['month']}
                                                            onNavigate={(e) => handleChange(e)}
                                                            onSelectEvent={(e) => handleOpenModal(e)}
                                                        />
                                                    </div>
                                                </div> 
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </section>
                    <DetailModal modal={modal} event={state.event} handleCloseModal={handleCloseModal} />
                </React.Fragment>
            }
        </React.Fragment>    
    )
}

export default Calendar;