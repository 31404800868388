import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import PageNotFound from '../pageNotFound/pageNotFound';

const Success = props => {
    const location = useLocation();
    const cookies = new Cookies();

    const useStyles = makeStyles((theme) => ({
        homeButtonStyle: {
            color: "#fff !important",
            height: '45px',
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
            [theme.breakpoints.up('xs')]: { width: '230px' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '240px' },
        },
        loginButtonStyle: {
            width: '160px',
            color: "#fff !important",
            height: '45px',
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const classes = useStyles();

    return(
        <React.Fragment>
            {location.state ?
            <section className="verticalAlign">
                <div className="container">
                    <div className="col-lg-12 successContainer">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-xs-12">
                                <Card variant="outlined">
                                    <CardContent>
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Typography variant="h4" className={classes.h4}>
                                                    {location.state.title}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row pt-3 pb-2">
                                            <div className="col-lg-12 text-center">
                                                <Typography className="mt-3" variant="body2">
                                                    {location.state.subTitle}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row pt-4 pb-4">
                                            <div className="col-lg-12 text-center">
                                                {location.state.redirect==="home" &&
                                                    <Button className={classes.homeButtonStyle} href="/home">RETURN TO THE HOME PAGE</Button>
                                                }
                                                {location.state.redirect==="login" &&
                                                    <Button className={classes.loginButtonStyle} href="/login">RETURN TO LOGIN</Button>
                                                }
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>      
                </div>
            </section>
            : <PageNotFound />
            }
        </React.Fragment>
    )
}

export default Success;
