import { isEmail, isName, passwordsMatch, isPassword } from '../validations';

const validateSignUp = (values) => {
    const errors = {};
    if(!passwordsMatch(values.password, values.repeatPassword)){
        errors.repeatPassword = 'Passwords must match';
    }
    if(!isPassword(values.password)){
        errors.password = 'Password must have at least 8 characters, 1 uppercase letter, 1 number and 1 special character';
    }
    if(!values.password){
        errors.password = 'Please enter a password';
    }
    return errors
}

export default validateSignUp;