import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import UploadCSV from './uploadCSV';
import validateFood from '../../functions/validations/validateFood';
import { reqQuickVerify } from '../../functions/requests/users';
import { reqSaveFood } from '../../functions/requests/foods';
import { redirectLogin, handleError, removeMessages, disableRequest } from '../../functions/global';

const AddFood = () => {
    const [state, setState] = useState({
        access: false,
        admin: false,
        name: "",
        portion: "",
        calories: "",
        carbs: "",
        protein: "",
        fats: "",
        visibility: "1",
        errors: {},
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false   
    });

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(!resQuickVerify.data.success){
                    redirectLogin();
                }else{
                    if(resQuickVerify.data.admin){
                        setState(prevState => ({ ...prevState, access: true, admin: true }));
                    }else{
                        setState(prevState => ({ ...prevState, access: true }));
                    }
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            height: '45px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        uploadButtonStyle: {
            marginLeft: '15px',
            width: '160px',
            color: "#fff",
            boxShadow: '0px 0px 0px 0px #fff',
            backgroundColor: "#37a37c",
            '&:hover': {
                boxShadow: '0px 0px 0px 0px #fff',
                backgroundColor: "#37a37c",
            },
        },
        inputStyle: {            
            width: '90%'
        }
    }));

    const GreenRadio = withStyles({
        root: {
            color: '#37a37c',
            '&checked': {
                color: '#37a37c',
            },
        }
    })((props) => <Radio color="default" {...props} />);

    const handleSubmitAdd = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateFood(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resSaveFood = await reqSaveFood(state);
                    if(resSaveFood.data.success){
                        setState(prevState => ({ 
                            ...prevState,
                            name: "",
                            portion: "",
                            calories: "",
                            carbs: "",
                            protein: "",
                            fats: "",
                            visibility: "1",
                            requestSuccess: true, 
                            requestSuccessMessage: 'Food item successfully created.', 
                            disabledRequest: false,
                            requestError: false
                        }));
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));        
    }

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.access &&
                <section>
                    <div className="container">
                        <div className="col-lg-12 py-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Card variant="outlined">
                                        <CardContent>
                                            <div className="row pt-4 pb-2">
                                                <div className="col-lg-12 text-center">
                                                    <Typography variant="h4">
                                                        Add food
                                                    </Typography>
                                                </div>
                                            </div>
                                            {state.requestError &&
                                                <div className="row py-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="error">{state.requestErrorMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            {state.requestSuccess &&
                                                <div className="row py-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="success">{state.requestSuccessMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            <Typography className="ml-3 pt-2" variant="h5" component="h2">
                                                Add single food
                                            </Typography>         
                                            <form onSubmit={(e) => handleSubmitAdd(e)}>                           
                                                <div className="row pt-3">
                                                    <div className="col-lg-12 text-center">
                                                        <div className="row pb-4">
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="name" label="Name" value={state.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.name && 
                                                                            <small className="errorSmall">{state.errors.name}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="portion" label="Portion" value={state.portion} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.portion && 
                                                                            <small className="errorSmall">{state.errors.portion}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="calories" label="Calories" value={state.calories} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.calories && 
                                                                            <small className="errorSmall">{state.errors.calories}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pb-1">
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="carbs" label="Carbs" value={state.carbs} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.carbs && 
                                                                            <small className="errorSmall">{state.errors.carbs}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="protein" label="Protein" value={state.protein} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.protein && 
                                                                            <small className="errorSmall">{state.errors.protein}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="fats" label="Fats" value={state.fats} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.fats && 
                                                                            <small className="errorSmall">{state.errors.fats}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pb-1 mt-4 addFoodVisibilityRowMbNMobile">
                                                            <div className="col-lg-4 col-xs-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-left addFoodVisibilityCol">    
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel className="grayMediumLabel" component="legend">Visibility</FormLabel>
                                                                            <FormLabel className="mt-2 grayMediumLabel" component="legend"><small>(Public must be approved by admin to be shared)</small></FormLabel>
                                                                            <RadioGroup aria-label="visibility" name="visibility" value={state.visibility} onChange={(e) => handleChange(e)}>
                                                                                <div className="row addFoodVisibilityRow">
                                                                                    <FormControlLabel value="1" labelPlacement="end" color="primary" control={<GreenRadio />} label="Public" />
                                                                                    <FormControlLabel value="2" labelPlacement="end" color="primary" control={<GreenRadio />} label="Private" />
                                                                                </div>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                            
                                                </div>
                                                <div className="row pt-4 pb-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>Add</Button>
                                                    </div>
                                                </div>
                                            </form>
                                            {state.admin &&
                                                <UploadCSV classes={classes}/>
                                            }
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>      
                    </div>
                </section>
            }
        </React.Fragment>
    )
}

export default AddFood;