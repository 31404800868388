import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../../config/config.json';
import CryptoJS from 'crypto-js';

export async function reqLogin(email, password){
    const hash = CryptoJS.AES.encrypt(password, config.CALORIAS_LOGIN_KEY).toString();
    const params = new URLSearchParams();
    params.append('email', email.trim());
    params.append('password', hash);
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/login',
        data: params
    });
    return request;
};

export async function reqSignUp(name, surname, email, password){
    const hash = CryptoJS.AES.encrypt(password, config.CALORIAS_LOGIN_KEY).toString();
    const params = new URLSearchParams();
    params.append('name', name.trim());
    params.append('surname', surname.trim());
    params.append('email', email.trim());
    params.append('password', hash);
    const request = axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/signUp',
        data: params
    });
    return request;
};

export async function reqEmailExists(email){
    const params = new URLSearchParams();
    params.append('email', email.trim());
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/emailExists',
        data: params
    });
    return request;
};

export async function reqVerifyEmail(token){
    const params = new URLSearchParams();
    params.append('emailVerificationToken', token);
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/verifyEmail',
        data: params
    });
    return request;
};

export async function reqForgotPassword(email){
    const params = new URLSearchParams();
    params.append('email', email.trim());
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/forgotPassword',
        data: params
    });
    return request;
};

export async function reqVerifyRecoverPassword(token){
    const params = new URLSearchParams();
    params.append('passwordRecoverToken', token);
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/verifyRecoverPassword',
        data: params
    });
    return request;
};

export async function reqRecoverPassword(token, password){
    const hash = CryptoJS.AES.encrypt(password, config.CALORIAS_LOGIN_KEY).toString();
    const params = new URLSearchParams();
    params.append('passwordRecoverToken', token);
    params.append('password', hash);
    const request = await axios({
        method: 'post',
        url: config.CALORIAS_BACKEND+'users/recoverPassword',
        data: params
    });
    return request;
};

export async function reqMyAccountLoad(){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/myAccountLoad'
    });
    return request;
};

export async function reqSaveMyAccount(userState){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('name', userState.name.trim());
    params.append('surname', userState.surname.trim());
    params.append('height', userState.height);
    params.append('weight', userState.weight);
    params.append('age', userState.age);
    params.append('gender', userState.gender);
    params.append('activityLevel', userState.activityLevel);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/saveMyAccount',
        data: params
    });
    return request;
};

export async function reqChangePassword(password){
    const hash = CryptoJS.AES.encrypt(password, config.CALORIAS_LOGIN_KEY).toString();
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('password', hash);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/changePassword',
        data: params
    });
    return request;
};

export async function reqQuickVerify(){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/quickVerify',
    });
    return request;
};

export async function reqLoadUsers(){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/loadUsers',
    });
    return request;
}

export async function reqUpdateUserState(id, state){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('id', id);
    params.append('state', state);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/updateUserState',
        data: params
    });
    return request;
}

export async function reqSystemData(){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'users/getSystemData'
    });
    return request;
}