import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

const PageNotFound = () => {
    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '240px',
            color: "#fff !important",
            height: '45px',
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
            [theme.breakpoints.up('xs')]: { width: '230px' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '240px' },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const classes = useStyles();

    return(
        <section className="verticalAlign">
            <div className="container">
                <div className="col-lg-12 pageNotFoundContainer">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xs-12">
                            <Card variant="outlined">
                                <CardContent>
                                    <div className="row pt-4">
                                        <div className="col-lg-12 text-center">
                                            <Typography variant="h4" className={classes.h4}>
                                                Page not found
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-lg-12 text-center">
                                            <Typography className="mt-3" variant="body2">
                                                We can't find the page you're looking for.
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row py-4">
                                        <div className="col-lg-12 text-center">
                                            <Button className={classes.buttonStyle} href="/home">RETURN TO THE HOME PAGE</Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    );
}

export default PageNotFound;