import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import { useParams, useHistory } from 'react-router-dom';
import validateRecoverPassword from '../../functions/validations/validateRecoverPassword';
import { reqVerifyRecoverPassword, reqRecoverPassword } from '../../functions/requests/users';
import { handleError, disableRequest } from '../../functions/global';
import PageNotFound from '../pageNotFound/pageNotFound';

const RecoverPassword = () => {
    const [state, setState] = useState({
        token: "",
        password: "",
        repeatPassword: "",
        notFound: false,
        success: false,
        disabledRequest: false,
        errors: {},
        requestError: false,
        requestErrorMessage: ""
    });

    var { token } = useParams();

    useEffect(() => {
        if(parseInt(token.length)===60){
            (async () => {
                try{
                    const resVerifyRecoverPassword = await reqVerifyRecoverPassword(token);
                    if(resVerifyRecoverPassword.data.success){
                        setState(prevState => ({ ...prevState, success: true, token: token }));
                    }else{
                        setState(prevState => ({ ...prevState, notFound: true }));
                    }
                }catch(error){
                    setState(prevState => ({ ...prevState, notFound: true }));
                }
            })();
        }else{
            setState(prevState => ({ ...prevState, notFound: true }));
        }
    }, []);

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '180px',
            height: '45px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        inputStyle: {            
            [theme.breakpoints.up('xs')]: { width: '90%' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '60%' },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateRecoverPassword(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resRecoverPassword = await reqRecoverPassword(token, state.password);
                    if(resRecoverPassword.data.success){
                        success();
                    }
                    if(!resRecoverPassword.data.success || resRecoverPassword.data.error){
                        setState(prevState => ({ ...prevState, requestError: true, requestErrorMessage: resRecoverPassword.data.error, disabledRequest: false }));
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    let history = useHistory();

    const success = () => {
        history.push({
            pathname: '/success',
            state: { 
                title: 'Your password has been successfully updated!',
                subTitle: 'Now you can log in with your email and password.',
                redirect: 'login'
            }
        });
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.success &&
                <section className="verticalAlign">
                    <div className="container">
                        <div className="col-lg-12 recoverPasswordContainer">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-xs-12">
                                    <Card variant="outlined">
                                        <CardContent>
                                            <div className="row pt-4">
                                                <div className="col-lg-12 text-center">
                                                    <Typography variant="h4" className={classes.h4}>
                                                        Reset your password
                                                    </Typography>
                                                </div>
                                            </div>
                                            {state.requestError &&
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="error">{state.requestErrorMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row py-3">
                                                <div className="col-lg-12 text-center">
                                                    <form onSubmit={(e) => handleSubmit(e)}>
                                                        <div className="row py-1">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="password" label="Password" value={state.password} inputProps={{maxLength: 30}} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                        {state.errors.password && 
                                                                            <small className="errorSmall">{state.errors.password}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row py-1">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="repeatPassword" label="Repeat Password" value={state.repeatPassword} inputProps={{maxLength: 30}} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                        {state.errors.repeatPassword && 
                                                                            <small className="errorSmall">{state.errors.repeatPassword}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-5 pb-2">
                                                            <div className="col-lg-12 text-center">
                                                                <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>RESET PASSWORD</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>      
                    </div>
                </section>
            }
            {state.notFound &&
                <PageNotFound />
            }
        </React.Fragment>
    )
}

export default RecoverPassword;
