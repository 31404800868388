import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const Confirm = ({show, title, handleCloseConfirm}) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            position: 'absolute',
            width: 400,
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 50,
            borderRadius: '4px'
        },
        buttonStyle: {
            width: '130px',
            height: '40px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        closeButtonStyle: {
            width: '130px',
            height: '40px',
            backgroundColor: "#d6d6d6",
            '&:hover': {
                backgroundColor: "#d6d6d6",
            },  
        }
    }));

    const classes = useStyles();

    return (
        <Modal 
            open={show}
            onClose={() => handleCloseConfirm(false)}
            >         
            <div className={classes.modal}>
                <h5 className="text-center" id="simple-modal-title">{title}</h5>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="row pt-4">
                            <div className="col-6 text-center">
                                <Button className={classes.buttonStyle} onClick={() => handleCloseConfirm(true)}>YES</Button>
                            </div>
                            <div className="col-6 text-center">
                                <Button className={classes.closeButtonStyle} onClick={() => handleCloseConfirm(false)}>NO</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Confirm
