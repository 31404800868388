import React from 'react';
import Cookies from 'universal-cookie';

const LogOut = () => {
    const cookies = new Cookies;
    cookies.remove('_fc');
    window.location.replace('/home');
    return(
        <div className="loadingPage"></div>
    )
}

export default LogOut;
