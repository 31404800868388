import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import MaterialTable, { MTablePagination } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { reqFoodSearch } from '../../functions/requests/foods';
import ServingsModal from './servingsModal';
import EditModal from './editModal';
import Confirm from '../confirm/confirm';
import { reqLoadRecords, reqRemoveRecord } from '../../functions/requests/records';
import { convertMacronutrient, convertToFixed } from '../../functions/global';
import { redirectLogin } from '../../functions/global';

const DailyDiet = () => {
    const [state, setState] = useState({
        access: false,
        foodSearch: "",
        foodSearchResults: [],
        addedFood: [],
        searchRowData: [],
        servingRowData: [],
        servings: "",
        totalCalories: 0,
        totalCarbs: 0,
        totalProtein: 0,
        totalFat: 0,
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false
    });

    const [servingsModal, setServingsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {
        (async () => {
            try{
                const resLoadRecords = await reqLoadRecords();
                if(resLoadRecords.data.success && resLoadRecords.data.diary){
                    setState(prevState => ({ ...prevState,
                        access: true,
                        addedFood: resLoadRecords.data.diary.diaryResults, 
                        totalCalories: resLoadRecords.data.diary.calories,
                        totalCarbs: resLoadRecords.data.diary.carbs,
                        totalProtein: resLoadRecords.data.diary.protein,
                        totalFat: resLoadRecords.data.diary.fats,
                    }));
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            [theme.breakpoints.between('xs', 'sm')]: { width: '90%' },
            [theme.breakpoints.between('md', 'xl')]: { width: '60%' }
        },
        inputModalStyle: {
            width: '100%'
        },  
        modal: {
            position: 'absolute',
            width: 400,
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 50,
            borderRadius: '4px'
        },
        buttonStyle: {
            width: '130px',
            height: '40px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        closeButtonStyle: {
            width: '130px',
            height: '40px',
            backgroundColor: "#d6d6d6",
            '&:hover': {
                backgroundColor: "#d6d6d6",
            },  
        }
    }));

    const updateServingsDatatable = (diaryResults) => {
        diaryResults.calories = diaryResults.calories/10000;
        diaryResults.carbs = diaryResults.carbs/10000;
        diaryResults.protein = diaryResults.protein/10000;
        diaryResults.fats = diaryResults.fats/10000;
        setState(prevState => ({ ...prevState, addedFood: diaryResults }));
    }

    const sumsTotals = (calories, carbs, fats, protein, servings) => {
        calories = convertMacronutrient(calories);
        carbs = convertMacronutrient(carbs);
        fats = convertMacronutrient(fats);
        protein = convertMacronutrient(protein);
        var totalCalories = (state.totalCalories||0) + (calories * servings);
        var totalCarbs = (state.totalCarbs||0) + (carbs * servings);
        var totalProtein = (state.totalProtein||0) + (protein * servings);
        var totalFat = (state.totalFat||0) + (fats * servings);
        setState(prevState => ({ ...prevState,
            totalCalories: totalCalories,
            totalCarbs: totalCarbs, 
            totalProtein: totalProtein, 
            totalFat: totalFat }));
    }

    const subsTotals = (calories, carbs, fats, protein, servings) => {
        calories = convertMacronutrient(calories);
        carbs = convertMacronutrient(carbs);
        fats = convertMacronutrient(fats);
        protein = convertMacronutrient(protein);
        var totalCalories = state.totalCalories - (calories * servings);
        var totalCarbs = state.totalCarbs - (carbs * servings);
        var totalProtein = state.totalProtein - (protein * servings);
        var totalFat = state.totalFat - (fats * servings);
        setState(prevState => ({ ...prevState,
            totalCalories: totalCalories,
            totalCarbs: totalCarbs, 
            totalProtein: totalProtein, 
            totalFat: totalFat }));
    }

    const updateTotals = (calories, carbs, fats, protein, servings, oldServings) => {
        calories = convertMacronutrient(calories);
        carbs = convertMacronutrient(carbs);
        fats = convertMacronutrient(fats);
        protein = convertMacronutrient(protein);
        var newCalories = (calories * servings) - (calories * oldServings);
        var newCarbs = (carbs * servings) - (carbs * oldServings);
        var newFats = (fats * servings) - (fats * oldServings);
        var newProtein = (protein * servings) - (protein * oldServings);
        //update current+difference
        var totalCalories = state.totalCalories + newCalories;
        var totalCarbs = state.totalCarbs + newCarbs;
        var totalProtein = state.totalProtein + newProtein;
        var totalFat = state.totalFat + newFats;
        setState(prevState => ({ ...prevState,
            totalCalories: totalCalories,
            totalCarbs: totalCarbs, 
            totalProtein: totalProtein, 
            totalFat: totalFat }));
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name] : value }));
        if(value){
            (async () => {
                try{
                    const resFoodSearch = await reqFoodSearch(value);
                    setState(prevState => ({ ...prevState, foodSearchResults: resFoodSearch.data.foodList }));
                }catch(error){
                    
                }
            })();
        }else{
            setState(prevState => ({ ...prevState, foodSearchResults: [] }));
        }
    }

    const columnsFoodSearchResults = [
        { field: 'name', title: 'Food/Meal' },
        { field: 'portion', title: 'Portion' },
        { field: 'calories', title: 'Calories' },
        { field: 'carbs', title: 'Carbs' },
        { field: 'protein', title: 'Protein' },
        { field: 'fats', title: 'Fat' },
    ];
    const columnsAddedFood = [
        { field: 'name', title: 'Food/Meal' },
        { field: 'portion', title: 'Portion' },
        { field: 'calories', title: 'Calories' },
        { field: 'carbs', title: 'Carbs' },
        { field: 'protein', title: 'Protein' },
        { field: 'fats', title: 'Fat' },
        { field: 'servings', title: 'Servings' },
    ];

    const handleOpenServingsModal = (searchRowData) => {
        setState(prevState => ({ ...prevState, searchRowData: searchRowData }));
        setServingsModal(!servingsModal);
    };

    const handleOpenEditModal = (servingRowData) => {
        setState(prevState => ({ ...prevState, servingRowData: servingRowData }));
        setEditModal(!editModal);
    };

    const handleCloseServingsModal = () => {
        setServingsModal(!servingsModal);
    };

    const handleCloseEditModal = () => {
        setEditModal(!editModal);
    };

    const handleDeleteServing = (servingRowData) => {
        setState(prevState => ({ ...prevState, servingRowData: servingRowData }));
        setShowDeleteConfirm(!showDeleteConfirm);
    };

    const handleDeleteConfirm = (confirm) => {
        if(confirm){
            (async () => {
                try{
                    const resRemoveRecord = await reqRemoveRecord(state.servingRowData.id, parseFloat(state.servingRowData.servings), state.servingRowData.calories, state.servingRowData.carbs, state.servingRowData.fats, state.servingRowData.protein);
                    if(resRemoveRecord.data.success){
                        updateServingsDatatable(resRemoveRecord.data.diaryResults.diaryResults);
                        subsTotals(state.servingRowData.calories, state.servingRowData.carbs, state.servingRowData.fats, state.servingRowData.protein, parseFloat(state.servingRowData.servings));
                    }
                }catch(error){
                    
                }
            })();
        }
        setShowDeleteConfirm(!showDeleteConfirm);
    };

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.access &&
                <React.Fragment>
                    <section>
                        <div className="container">
                            <div className="col-lg-12 py-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Card variant="outlined">
                                            <CardContent>
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Typography variant="h4">
                                                            Daily diet
                                                        </Typography>
                                                    </div>
                                                    <div className="col-lg-12 text-center my-3">
                                                        <Typography variant="subtitle1">Calories consumed today:</Typography>
                                                        <Typography variant="h4">{convertToFixed(state.totalCalories/10000, 1) || 0}</Typography>
                                                    </div>
                                                    <div className="col-lg-12 text-center">
                                                        <Typography variant="body2">
                                                            Search for a meal or a food and add it to your daily intake:
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row pt-2 pb-3">
                                                    <div className="col-lg-12 text-center">
                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChangeSearch(e)} name="foodSearch" label="Search a Food" value={state.foodSearch} />
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-lg-12 text-center tableSearchFoodContainer">
                                                    <MaterialTable
                                                        columns={columnsFoodSearchResults}
                                                        data={state.foodSearchResults}
                                                        actions={[
                                                            {
                                                            icon: 'add',
                                                            tooltip: 'Add Serving',                                                    
                                                            onClick: (event, searchRowData) => handleOpenServingsModal(searchRowData)
                                                            }
                                                        ]}
                                                        options={{
                                                            actionsColumnIndex: -1,
                                                            showTitle: false,
                                                            pageSize: 5,
                                                            search: false
                                                        }}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-left">
                                                        <Typography className="ml-3" variant="h5" component="h2">
                                                            Daily summary:
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-lg-12 text-center tableAddedFoodContainer">
                                                        <MaterialTable
                                                            components={{
                                                                Pagination: props => (
                                                                    <td className="tdMTablePagination">
                                                                        <div className="row mt-3">
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <span><span className="font-weight-soft-bold">Calories</span>: {convertToFixed(state.totalCalories/10000, 1)}</span>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <span><span className="font-weight-soft-bold">Carbs</span>: {convertToFixed(state.totalCarbs/10000, 2)}</span>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <span><span className="font-weight-soft-bold">Protein</span>: {convertToFixed(state.totalProtein/10000, 2)}</span>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <span><span className="font-weight-soft-bold">Fat</span>: {convertToFixed(state.totalFat/10000, 2)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                                        </div>
                                                                        <MTablePagination {...props} classes={{}} />
                                                                    </td>
                                                                )
                                                            }}
                                                            columns={columnsAddedFood}
                                                            data={state.addedFood}
                                                            actions={[
                                                                {
                                                                icon: 'edit',
                                                                tooltip: 'Edit Serving',                                                    
                                                                onClick: (event, servingRowData) => handleOpenEditModal(servingRowData)
                                                                },
                                                                {
                                                                icon: 'delete',
                                                                tooltip: 'Delete Serving',
                                                                onClick: (event, servingRowData) => handleDeleteServing(servingRowData)
                                                                }
                                                            ]}
                                                            options={{
                                                                actionsColumnIndex: -1,
                                                                showTitle: false,
                                                                pageSize: 5
                                                            }}
                                                        />
                                                    </div>
                                                </div>  
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </section>
                    <ServingsModal modal={servingsModal} handleCloseServingsModal={handleCloseServingsModal} searchRowData={state.searchRowData} updateServingsDatatable={updateServingsDatatable} sumsTotals={sumsTotals} classes={classes} />
                    <EditModal modal={editModal} handleCloseEditModal={handleCloseEditModal} servingRowData={state.servingRowData} updateServingsDatatable={updateServingsDatatable} updateTotals={updateTotals} classes={classes} />
                    <Confirm show={showDeleteConfirm} title="Are you sure you want to remove this item?" handleCloseConfirm={handleDeleteConfirm} />
                </React.Fragment>
            }                                                
        </React.Fragment>
    )
}

export default DailyDiet;