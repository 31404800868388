import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../../config/config.json';

export async function reqLoadDiariesByMonth(startOfMonth, endOfMonth){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('startOfMonth', startOfMonth);
    params.append('endOfMonth', endOfMonth);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'diaries/loadDiariesByMonth',
        data: params
    });
    return request;
};