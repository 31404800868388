import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { convertToFixed } from '../../functions/global';

const DetailModal = ({ modal, event, handleCloseModal }) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            position: 'absolute',
            width: 400,
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 50,
            borderRadius: '4px'
        },
        buttonStyle: {
            width: '130px',
            height: '40px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        }
    }));

    const classes = useStyles();

    return (
        <Modal 
            open={modal}
            onClose={handleCloseModal}
            >         
            <div className={classes.modal}>
                <h5 className="text-center" id="simple-modal-title">Detail from {event.date}</h5>
                <div className="row pt-2">
                    <div className="col-lg-12 text-center">
                        <div className="row justify-content-center py-2">
                            <div className="col-12">
                                <div className="row mb-1">
                                    <div className="col-6">
                                        <span className="float-left">Calories: {convertToFixed(event.calories/10000, 1)}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="float-left">BMI: {(event.BMI) ? event.BMI : 'No data'}</span>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        <span className="float-left">Carbs: {convertToFixed(event.carbs/10000, 2)}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="float-left">BMR: {(event.BMR) ? event.BMR+' calories' : 'No data'}</span>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        <span className="float-left">Protein: {convertToFixed(event.protein/10000, 2)}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="float-left">Height: {(event.height) ? event.height+'cm' : 'No data'}</span>
                                     </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="float-left">Fats: {convertToFixed(event.fats/10000, 2)}</span>
                                    </div>
                                    <div className="col-6">
                                        <span className="float-left">Weight: {(event.weight) ? event.weight+'kg' : 'No data'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center pt-4">
                            <div className="col-6 text-center">
                                <Button type="submit" className={classes.buttonStyle} onClick={() => handleCloseModal()}>OK</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DetailModal;
