import { hasValidExtension } from '../validations';

const validateUploadCSV = (values) => {
    const errors = {};
    if(!hasValidExtension(values.fileName, 'csv')){
        errors.file = 'Please upload a valid CSV file';
    }
    if(!values.file){
        errors.file = 'Please upload a CSV file';
    }
    return errors
}

export default validateUploadCSV;