import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import validateSignUp from '../../functions/validations/validateSignUp';
import { reqEmailExists, reqSignUp } from '../../functions/requests/users';
import { handleError, disableRequest } from '../../functions/global';

const SignUp = () => {
    const [state, setState] = useState({
        email: "",
        password: "",
        repeatPassword: "",
        name: "",
        surname: "",
        disabledRequest: false,
        errors: {},
        requestError: false,
        requestErrorMessage: ""
    });

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            height: '45px',
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        inputStyle: {            
            [theme.breakpoints.up('xs')]: { width: '90%' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '60%' },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateSignUp(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resEmailExists = await reqEmailExists(state.email);
                    if(resEmailExists.data.success){
                        const resSignUp = await reqSignUp(state.name, state.surname, state.email, state.password);
                        if(resSignUp.data.success){
                            success();
                        }
                        if(!resSignUp.data.success){
                            state.errors.email = resSignUp.data.message;
                            setState(prevState => ({ ...prevState, errors: state.errors, disabledRequest: false }));
                        }
                        if(resSignUp.data.error){
                            setState(prevState => ({ ...prevState, requestError: true, requestErrorMessage: resSignUp.data.error, disabledRequest: false }));
                        }
                    }else{
                        state.errors.password = undefined;
                        state.errors.repeatPassword = undefined;
                        state.errors.name = undefined;
                        state.errors.surname = undefined;
                        state.errors.email = resEmailExists.data.message;
                        setState(prevState => ({ ...prevState, errors: state.errors, disabledRequest: false }));
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    let history = useHistory();

    const success = () => {
        history.push({
            pathname: '/success',
            state: { 
                title: 'Your account has been successfully created!',
                subTitle: 'Please check your email for a confirmation link.',
                redirect: 'home'
            }
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <section className="verticalAlign">
            <div className="container">
                <div className="col-lg-12 signUpContainer">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xs-12">
                            <Card variant="outlined">
                                <CardContent>
                                    <div className="row pt-4">
                                        <div className="col-lg-12 text-center">
                                            <Typography variant="h4" className={classes.h4}>
                                                Join the community!
                                            </Typography>
                                        </div>
                                    </div>
                                    {state.requestError &&
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Alert severity="error">{state.requestErrorMessage}</Alert>
                                            </div>
                                        </div>
                                    }
                                    <div className="row py-3">
                                        <div className="col-lg-12 text-center">
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-ecnter">
                                                                <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="email" label="Email" value={state.email} inputProps={{maxLength: 255}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.email && 
                                                                    <small className="errorSmall">{state.errors.email}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="password" label="Password" value={state.password} inputProps={{maxLength: 30}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.password && 
                                                                    <small className="errorSmall">{state.errors.password}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="repeatPassword" label="Repeat Password" value={state.repeatPassword} inputProps={{maxLength: 30}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.repeatPassword && 
                                                                    <small className="errorSmall">{state.errors.repeatPassword}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="name" label="Name" value={state.name} inputProps={{maxLength: 25}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.name && 
                                                                    <small className="errorSmall">{state.errors.name}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="surname" label="Surname" value={state.surname} inputProps={{maxLength: 25}} />
                                                                </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.surname && 
                                                                    <small className="errorSmall">{state.errors.surname}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-5 pb-2">
                                                    <div className="col-lg-12 text-center">
                                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>SIGN UP!</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    )
}

export default SignUp;