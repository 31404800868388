import { isEmail } from '../validations';

const validateLogin = (values) => {
    const errors = {};
    if(!values.password){
        errors.password = 'Please enter a password';
    }
    if(!isEmail(values.email)){
        errors.email = 'Please enter a valid email';
    }
    if(!values.email){
        errors.email = 'Please enter an email';
    } 
    return errors
}

export default validateLogin;