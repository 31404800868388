//valid email
export function isEmail(email){
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}
//max 25 chars + only letters + accents or ñ
export function isName(name){
    return /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{1,25}$/.test(name);
}
//value is not only white spaces
export function isNotOnlyWhitespace(value){
    return value.replace(/\s/g, '').length;
}
//passwords match
export function passwordsMatch(password, repeatPassword){
    return (password === repeatPassword);
}
//password min 8 chars + one mayus + one number + one special char
export function isPassword(password){
    return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_*?&])[A-Za-z\d@$!%_*?&]{8,30}$/.test(password);
}
//valid integer or decimal value
export function isDecimal(number){
    return /^[-+]?[0-9]+\.[0-9]+$/.test(number);
}
//valid age, between 0 and 125 years old
export function isAge(age){
    return (/^\d+$/.test(age) && parseInt(age) >= 0 && parseInt(age) <= 125);
}
//valid height in centimeters (between 50cm and 250cm)
export function isHeight(height){
    return (/^\d+$/.test(height) && parseInt(height) >= 50 && parseInt(height) <= 250);
}
//valid weight in kg (between 3kg and 300kg)
export function isWeight(weight){
    return ((/^[-+]?[0-9]+\.[0-9]+$/.test(weight) || /^\d+$/.test(weight)) && parseFloat(weight) >= 3 && parseFloat(weight) <= 300);
}
//valid serving (between 0.10 and 10.00)
export function isServing(serving){
    return ((/^[-+]?[0-9]+\.[0-9]+$/.test(serving) || /^\d+$/.test(serving)) && parseFloat(serving) >= 0.1 && parseFloat(serving) <= 10.0);
}
//valid integer
export function isInteger(number){
    return /^\d+$/.test(number);
}
//valid integer or decimal
export function isIntegerOrDecimal(number){
    return (/^\d+$/.test(number) || /^[-+]?[0-9]+\.[0-9]+$/.test(number));
}
//valid greater than zero
export function isGreaterThanZero(number){
    return (parseFloat(number) > 0);
}
//valid UUID
export function isUUID(uuid){
    return /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/.test(uuid)
}
//valid quantity of digits after point
export function hasValidDecimals(value, decimals){
    var split = value.split('.');
    if(split.length === 1) return true;
    return (split[1].length <= decimals);
}
//valid extension
export function hasValidExtension(fileName, extension){
    var split = fileName.split('.');
    return (split[split.length-1] === extension || split[split.length-1] === (extension.toUpperCase()));
}