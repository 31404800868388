import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './modules/home/home';
import SignUp from './modules/signUp/signUp';
import LogIn from './modules/login/login';
import LogOut from './modules/logout/logout';
import Success from './modules/success/success';
import MyAccount from './modules/myAccount/myAccount';
import Calendar from './modules/calendar/calendar';
import DailyDiet from './modules/dailyDiet/dailyDiet';
import AddFood from './modules/addFood/addFood';
import MyPassword from './modules/myPassword/myPassword';
import ForgotPassword from './modules/forgotPassword/forgotPassword';
import ManageFood from './modules/manageFood/manageFood';
import ManageUsers from './modules/manageUsers/manageUsers';
import VerifyEmail from './modules/verifyEmail/verifyEmail';
import RecoverPassword from './modules/recoverPassword/recoverPassword';
import Statistics from './modules/statistics/statistics';
import PageNotFound from './modules/pageNotFound/pageNotFound';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

render(
  <Router>
        <App>
            <Switch>
                <Route path="/" exact render={()=> <Redirect to="/home"/>} />
                <PublicRoute restricted={true} path="/home" exact component={Home} />
                <PublicRoute restricted={true} path="/signup" exact component={SignUp} />
                <PublicRoute restricted={true} path="/login" exact component={LogIn} />
                <PublicRoute restricted={true} path="/success" exact component={Success} />    
                <PublicRoute restricted={true} path="/verifyemail/:token" exact component={VerifyEmail} /> 
                <PublicRoute restricted={true} path="/forgotpassword" exact component={ForgotPassword} />    
                <PublicRoute restricted={true} path="/recoverpassword/:token" exact component={RecoverPassword} />    
                <PrivateRoute path="/myaccount" exact component={MyAccount} />           
                <PrivateRoute path="/calendar" exact component={Calendar} />    
                <PrivateRoute path="/dailydiet" exact component={DailyDiet} />  
                <PrivateRoute path="/addfood" exact component={AddFood} />                       
                <PrivateRoute path="/mypassword" exact component={MyPassword} />
                <PrivateRoute path="/managefood" exact component={ManageFood} />
                <PrivateRoute path="/manageusers" exact component={ManageUsers} />
                <PrivateRoute path="/statistics" exact component={Statistics} />        
                <PrivateRoute path="/logout" exact component={LogOut} />
                <Route component={PageNotFound} />
            </Switch>
        </App>
    </Router>,
    document.getElementById("root")
);