import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { reqQuickVerify, reqSystemData } from '../../functions/requests/users';
import { redirectLogin } from '../../functions/global';

const Statistics = () => {
    const [state, setState] = useState({    
        access: false,
        serverDate: null,
        foodCount: null,
        userCount: null,
        log: null
    });

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(resQuickVerify.data.admin){
                    const resSystemData = await reqSystemData();
                    setState(prevState => ({ ...prevState, 
                        access: true,
                        serverDate: resSystemData.data.systemData.serverDate,
                        foodCount: resSystemData.data.systemData.foodCount, 
                        userCount: resSystemData.data.systemData.userCount,
                        log: resSystemData.data.systemData.log
                    }));
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);
    
    const useStyles = makeStyles({
        table: {
          minWidth: 400,
        },
        buttonStyle: {
            width: '250px',
            height: '45px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            }
        },
    });

    const handleShowLog = () => {
        console.clear();
        console.log(state.log);
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            {state.access &&
                <section>
                    <div className="container">
                        <div className="col-lg-12 py-5">
                            <Card variant="outlined">
                                <CardContent>
                                    <div className="row pt-4">
                                        <div className="col-lg-12 text-center">
                                            <Typography variant="h4">
                                                Statistics
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 offset-lg-3 col-xs-12 justify-content-center d-flex mt-4">
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <colgroup>
                                                    <col style={{width:'50%'}}/>
                                                    <col style={{width:'50%'}}/>
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontWeight:'bold'}} align="left">Data</TableCell>
                                                        <TableCell style={{fontWeight:'bold'}} align="left">Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key="serverDate">
                                                        <TableCell align="left">Server datetime</TableCell>
                                                        <TableCell align="left">{state.serverDate}</TableCell>
                                                    </TableRow>
                                                    <TableRow key="foodCount">
                                                        <TableCell align="left">Number of foods</TableCell>
                                                        <TableCell align="left">{state.foodCount}</TableCell>
                                                    </TableRow>
                                                    <TableRow key="userCount">
                                                        <TableCell align="left">Number of users</TableCell>
                                                        <TableCell align="left">{state.userCount}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                        </div> 
                                    </div>
                                    <div className="row mt-4 mb-4 justify-content-center">
                                        <div className="col-lg-6 col-xs-12 d-flex justify-content-center mt-2">
                                            <Button type="submit" className={classes.buttonStyle} onClick={handleShowLog}>SHOW SERVER LOG ON CONSOLE</Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </section>      
            }
        </React.Fragment>
    )
}

export default Statistics
