import { isEmail, isName, isInteger, isAge, isIntegerOrDecimal, hasValidDecimals, isHeight, isWeight, isNotOnlyWhitespace } from '../validations';

const validateMyAccount = (values) => {
    const errors = {};

    if(!values.activityLevel){
        errors.activityLevel = 'Please enter an activity level';
    }
    if(!values.gender){
        errors.gender = 'Please enter a gender';
    }
    if(!isAge(values.age)){
        errors.age = 'Please enter a valid age';
    }
    if(!values.age){
        errors.age = 'Please enter an age';
    }
    if(!isWeight(values.weight)){
        errors.weight = 'Please enter a valid weight';
    }
    if(!hasValidDecimals(values.weight, 1)){
        errors.weight = 'Maximum one decimal';
    }
    if(!isIntegerOrDecimal(values.weight)){
        errors.weight = 'Please enter a valid weight';
    }
    if(!values.weight){
        errors.weight = 'Please enter a weight';
    }
    if(!isHeight(values.height)){
        errors.height = 'Please enter a valid height';
    }
    if(!isInteger(values.height)){
        errors.height = 'Please enter a valid height';
    }
    if(!values.height){
        errors.height = 'Please enter a height';
    } 
    if(!isName(values.surname) || !isNotOnlyWhitespace(values.surname)){
        errors.surname = 'Please enter a valid surname';
    }
    if(!values.surname){
        errors.surname = 'Please enter a surname';
    }
    if(!isName(values.name) || !isNotOnlyWhitespace(values.name)){
        errors.name = 'Please enter a valid name';
    }
    if(!values.name){
        errors.name = 'Please enter a name';
    }
    if(!isEmail(values.email)){
        errors.email = 'Please enter a valid email';
    }
    if(!values.email){
        errors.email = 'Please enter an email';
    } 
    return errors
}

export default validateMyAccount;