import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import validateForgotPassword from '../../functions/validations/validateForgotPassword';
import { reqForgotPassword } from '../../functions/requests/users';
import { handleError, disableRequest } from '../../functions/global';

const ForgotPassword = () => {
    const [state, setState] = useState({
        email: "",
        success: false,
        disabledRequest: false,
        errors: {},
        requestError: false,
        requestErrorMessage: ""
    });

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            height: '45px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        largeButtonStyle: {
            color: "#fff !important",
            height: '45px',
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
            [theme.breakpoints.up('xs')]: { width: '230px' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '240px' },
        },
        inputStyle: {            
            [theme.breakpoints.up('xs')]: { width: '90%' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '60%' },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateForgotPassword(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resForgotPassword = await reqForgotPassword(state.email);
                    if(!resForgotPassword.data.error){
                        if(resForgotPassword.data.success){
                            setState(prevState => ({ ...prevState, success: true }));
                        }else{
                            state.errors.email = resForgotPassword.data.message;
                            setState(prevState => ({ ...prevState, errors: state.errors, disabledRequest: false }));
                        }
                    }else{
                        setState(prevState => ({ ...prevState, requestError: true, requestErrorMessage: resForgotPassword.data.error, disabledRequest: false }));
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <section className="verticalAlign">
            <div className="container">
                <div className="col-lg-12 forgotPassContainer">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xs-12">
                            <Card variant="outlined">
                                {(state.success===false) ? (
                                    <CardContent>
                                    <div className="row pt-4">
                                        <div className="col-lg-12 text-center">
                                            <Typography variant="h4" className={classes.h4}>
                                                Forgot password?
                                            </Typography>                                        
                                            <Typography className="px-2 mt-3" variant="body2">If you've forgotten your password, enter your email address below. We'll email you a link to a page where you can easily create a new password.</Typography>
                                        </div>
                                    </div>
                                    {state.requestError &&
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Alert severity="error">{state.requestErrorMessage}</Alert>
                                            </div>
                                        </div>
                                    }
                                    <div className="row py-3">
                                        <div className="col-lg-12 text-center">
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-ecnter">
                                                                <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="email" label="Email" value={state.email} inputProps={{maxLength: 255}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                {state.errors.email && 
                                                                    <small className="errorSmall">{state.errors.email}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-5 pb-2">
                                                    <div className="col-lg-12 text-center">
                                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>SEND EMAIL</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    </CardContent>
                                    ):  (
                                        <CardContent>
                                            <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Typography variant="h4">
                                                    Email sent!
                                                </Typography>                                        
                                                <Typography className="mt-3" variant="body2">An email has been sent to the address provided with instructions on how to reset your password.</Typography>
                                                <Typography variant="body2">The link is only valid for the next 30 minutes.</Typography>
                                            </div>
                                            </div>
                                            <div className="row pb-3">
                                                <div className="col-lg-12 text-center">
                                                    <div className="row pt-5 pb-2">
                                                        <div className="col-lg-12 text-center">
                                                        <Button className={classes.largeButtonStyle} href="/home">RETURN TO THE HOME PAGE</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                        )
                                    }
                            </Card>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    )
}

export default ForgotPassword;