import React from 'react';
import Header from './modules/header/header';
import Cookies from 'universal-cookie';
import './styles/styles.css';
import Wave from './images/wave.svg';

function App({ children }) {
  const cookies = new Cookies();
  const token = cookies.get('_fc');
  const path = new URL(window.location.href).pathname;
  return (
    <div className="App"
    style={{
      background: (path==='/signup' || path==='/login' || path==='/success' || path === '/forgotpassword') 
      || path.startsWith('/verifyemail') || path.startsWith('/recoverpassword')
        ? 'url('+Wave+') center bottom/cover no-repeat' : 'none'
    }}
    >
      <React.Fragment>
        <Header path={path} token={token} />
          {children}
      </React.Fragment>
    </div>
  );
}

export default App;