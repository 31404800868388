import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import validateMyAccount from '../../functions/validations/validateMyAccount';
import { reqMyAccountLoad, reqSaveMyAccount } from '../../functions/requests/users';
import { redirectLogin, handleError, removeMessages, disableRequest } from '../../functions/global';

const MyAccount = () => {
    const [state, setState] = useState({
        access: false,
        email: "",
        name: "",
        surname: "",
        height: "",
        weight: "",
        age: "",
        gender: "",
        activityLevel: "",
        BMI: "",    
        BMR: "",
        errors: {},
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false
    });

    useEffect(() => {
        (async () => {
            try{
                const resMyAccountLoad = await reqMyAccountLoad();
                if(resMyAccountLoad.data.success){
                    refreshUserData(resMyAccountLoad);
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            height: '45px',
            color: "#fff",
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        inputStyle: {            
            width: '90%'
        },
        select: {
            textAlign: 'left',
            width: '90%'
        }
    }));

    const refreshUserData = (res) => {
        setState(prevState => ({ ...prevState, 
            access: true,
            email: res.data.email, 
            name: res.data.name, 
            surname: res.data.surname, 
            height: res.data.height,
            weight: res.data.weight,
            age: res.data.age,
            gender: res.data.gender,
            activityLevel: res.data.activityLevel,
            BMI: res.data.BMI,
            BMR: res.data.BMR
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateMyAccount(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resSaveMyAccount = await reqSaveMyAccount(state);
                    if(resSaveMyAccount.data.success){
                        setState(prevState => ({ ...prevState, requestSuccess: true, requestSuccessMessage: 'User data successfully updated.', disabledRequest: false }));
                        refreshUserData(resSaveMyAccount);
                    }else{
                        handleError(setState);
                    }
                    removeMessages(setState, new URL(window.location.href).pathname);
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.access &&
                <section>
                    <div className="container">
                        <div className="col-lg-12 py-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Card variant="outlined">
                                        <CardContent>
                                            <div className="row pt-4 pb-2">
                                                <div className="col-lg-12 text-center">
                                                    <Typography variant="h4">
                                                        My account
                                                    </Typography>
                                                </div>
                                            </div>
                                            {state.requestError &&
                                                <div className="row py-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="error">{state.requestErrorMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            {state.requestSuccess &&
                                                <div className="row py-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Alert severity="success">{state.requestSuccessMessage}</Alert>
                                                    </div>
                                                </div>
                                            }
                                            <Typography className="ml-3 pt-2" variant="h5" component="h2">
                                                Personal data
                                            </Typography>
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="row pt-3">
                                                    <div className="col-lg-12 text-center">
                                                        <div className="row pb-1">
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center disabled-input">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="email" label="Email" value={state.email} disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.email && 
                                                                            <small className="errorSmall">{state.errors.email}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="name" label="Name" value={state.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.name && 
                                                                            <small className="errorSmall">{state.errors.name}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="surname" label="Surname" value={state.surname} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.surname && 
                                                                            <small className="errorSmall">{state.errors.surname}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Divider className="my-5" light />  
                                                <Typography className="ml-3" variant="h5" component="h2">
                                                    Height, weight and stats
                                                </Typography>
                                                <div className="row pt-3 pb-2">
                                                    <div className="col-lg-12 text-center">
                                                        <div className="row pb-1">
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="height" label="Height (cm)" value={state.height} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.height && 
                                                                            <small className="errorSmall">{state.errors.height}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="weight" label="Weight (kg.g)" value={state.weight} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.weight && 
                                                                            <small className="errorSmall">{state.errors.weight}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="age" label="Age" value={state.age} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.age && 
                                                                            <small className="errorSmall">{state.errors.age}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-3">
                                                            <div className="col-lg-4">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <FormControl className={classes.select}>
                                                                            <InputLabel id="genderLabel">Gender</InputLabel>
                                                                            <Select
                                                                                labelId="genderLabel"
                                                                                id="gender"
                                                                                name="gender"
                                                                                value={state.gender}
                                                                                onChange={(e) => handleChange(e)}
                                                                            >
                                                                                <MenuItem value="1">Male</MenuItem>
                                                                                <MenuItem value="2">Female</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.gender && 
                                                                            <small className="errorSmall">{state.errors.gender}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <FormControl className={classes.select}>
                                                                            <InputLabel id="activityLabel">Activity Level {state.activityLevel}</InputLabel>
                                                                            <Select
                                                                                labelId="activityLabel"
                                                                                id="activityLevel"
                                                                                name="activityLevel"
                                                                                value={state.activityLevel}
                                                                                onChange={(e) => handleChange(e)}
                                                                            >
                                                                                <MenuItem value="1">No sport/exercise</MenuItem>
                                                                                <MenuItem value="2">Light activity (sport 1-3 times per week)</MenuItem>
                                                                                <MenuItem value="3">Moderate activity (sport 3-5 times per week)</MenuItem>
                                                                                <MenuItem value="4">High activity (everyday exercise)</MenuItem>
                                                                                <MenuItem value="5">Extreme activity (professional athlete)</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.activityLevel && 
                                                                            <small className="errorSmall">{state.errors.activityLevel}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-margin-top-mobile">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center disabled-input">
                                                                        <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="BMI" label="BMI" value={state.BMI} disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        {state.errors.BMI && 
                                                                            <small className="errorSmall">{state.errors.BMI}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                                        
                                                        <div className="row pt-5">
                                                            <div className="col-lg-12 text-center mb-3">
                                                                <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>UPDATE</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {state.BMR !== "" &&
                                                    <React.Fragment>
                                                        <Divider className="dividerFix" light />
                                                        <div className="row pb-4">
                                                            <div className="col-lg-12 text-center">
                                                                <div className="row">
                                                                    <div className="col-lg-12 text-center">
                                                                        <Typography className="pb-3" variant="h4">
                                                                            Daily calorie intake
                                                                        </Typography>
                                                                        <Typography className="pb-1" variant="subtitle1">Daily calories to mantain weight:</Typography>
                                                                        <Typography className="pb-1" variant="h4">{state.BMR}</Typography>
                                                                        <Typography variant="subtitle1">Weight loss: <strong>{parseInt(state.BMR - state.BMR*0.2)}</strong>. Weight gain: <strong>{parseInt(state.BMR + state.BMR*0.2)}</strong>.</Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </form>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>      
                    </div>
                </section>
            }
        </React.Fragment>
    )
}

export default MyAccount;