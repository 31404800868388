import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Cookies from 'universal-cookie';
import validateLogin from '../../functions/validations/validateLogin';
import { reqLogin } from '../../functions/requests/users';
import { handleError, disableRequest } from '../../functions/global';

const LogIn = () => {
    const [state, setState] = useState({
        email: "",
        password: "",
        disabledRequest: false,
        errors: {},
        requestError: false,
        requestErrorMessage: ""
    });

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            height: '45px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            }
        },
        inputStyle: {            
            [theme.breakpoints.up('xs')]: { width: '90%' },
            [theme.breakpoints.between('sm', 'xl')]: { width: '60%' },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important', paddingLeft: '0.75rem', paddingRight: '0.75rem' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        },
    }));

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateLogin(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resLogin = await reqLogin(state.email, state.password);
                    if(!resLogin.data.error){
                        if(resLogin.data.success){
                            const cookies = new Cookies();
                            cookies.set('_fc', resLogin.data.token, { path:'/' });
                            window.location.replace('/dailydiet');
                        }else{
                            state.errors.email = undefined;
                            state.errors.password = resLogin.data.message;
                            setState(prevState => ({ ...prevState, errors: state.errors, disabledRequest: false }));
                        }
                    }else{
                        setState(prevState => ({ ...prevState, requestError: true, requestErrorMessage: resLogin.data.error, disabledRequest: false }));
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));
    }

    const classes = useStyles();

    return(
        <section className="verticalAlign">
            <div className="container">
                <div className="col-lg-12 loginContainer">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xs-12">
                            <Card variant="outlined">
                                <CardContent>
                                    <div className="row pt-4">
                                        <div className="col-lg-12 text-center">
                                            <Typography className={classes.h4} variant="h4">
                                                Log in with your email and password
                                            </Typography>
                                        </div>
                                    </div>
                                    {state.requestError &&
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Alert severity="error">{state.requestErrorMessage}</Alert>
                                            </div>
                                        </div>
                                    }
                                    <div className="row py-3">
                                        <div className="col-lg-12 text-center">
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-ecnter">
                                                                <TextField className={classes.inputStyle} onChange={(e) => handleChange(e)} name="email" label="Email" value={state.email} inputProps={{maxLength: 255}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.email && 
                                                                    <small className="errorSmall">{state.errors.email}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row py-1">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <TextField type="password" className={classes.inputStyle} onChange={(e) => handleChange(e)} name="password" label="Password" value={state.password} inputProps={{maxLength: 30}} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 text-center">
                                                                {state.errors.password && 
                                                                    <small className="errorSmall">{state.errors.password}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-5">
                                                    <div className="col-lg-12 text-center">
                                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>LOG IN!</Button>
                                                    </div>
                                                </div>
                                                <div className="row pt-3 pb-2">
                                                    <div className="col-lg-12 text-center">
                                                        <Link className="link" href="/forgotpassword">Forgot Password?</Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    )
}

export default LogIn;
