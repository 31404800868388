import React from 'react';
import Background from '../../images/background.jpeg';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const Home = () => {
    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '140px',
            height: '50px',
            fontSize: '16px',
            fontWeight: 600,
            color: "#fff!important",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
            marginTop: '20px',
            marginLeft: '5px',
            borderRadius: '18px'
        }
    }));
    
    const classes = useStyles();

    return (
        <div>
            <div className="homeBackground">
                <div className="homeContainer">
                    <div className="homeRow">
                        <h1 className="homeTitle">
                            Start Your Free <br/> 
                            Food Diary Today
                        </h1>
                        <p className="homeSpan">Whether you want to lose weight, tone up, get healthy, change your habits, or start a new diet, you’ll love FitConnect.</p>
                        <Button type="submit" className={classes.buttonStyle} href="/login">LOG IN</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;