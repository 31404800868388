import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { reqVerifyEmail } from '../../functions/requests/users';
import Countdown from "react-countdown";
import PageNotFound from '../pageNotFound/pageNotFound';

const VerifyEmail = () => {
    const [state, setState] = useState({
        ok: false,
        notFound: false,
        name: "",
        errors: {},
        requestError: false,
        requestErrorMessage: ""
    });

    var { token } = useParams();

    useEffect(() => {
        if(parseInt(token.length)===60){
            (async () => {
                try{
                    const resVerifyEmail = await reqVerifyEmail(token);
                    if(resVerifyEmail.data.success){
                        setState(prevState => ({ ...prevState, ok: true, name: resVerifyEmail.data.name }));
                    }else{
                        setState(prevState => ({ ...prevState, notFound: true }));
                    }
                }catch(error){
                    setState(prevState => ({ ...prevState, notFound: true }));
                }
            })();
        }else{
            setState(prevState => ({ ...prevState, notFound: true }));
        }
    }, []);

    const redirect = () => { window.location.replace('/login') }

    //countdown
    const renderer = ({ hours, minutes, seconds, completed }) => {
        return <span>{seconds}</span>;
    };

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '120px',
            height: '45px',
            color: '#fff!important',
            backgroundColor: '#37a37c',
            '&:hover': {
                backgroundColor: '#37a37c',
            },
        },
        h4: {            
            [theme.breakpoints.up('xs')]: { fontSize: '1.5rem!important' },
            [theme.breakpoints.between('sm', 'xl')]: { fontSize: '2rem!important' }
        }
    }));

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.ok &&
            <section className="verticalAlign">
                <div className="container">
                    <div className="col-lg-12 verifyEmailContainer">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-xs-12">
                                <Card variant="outlined">
                                    <CardContent>
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Typography variant="h4" className={classes.h4}>
                                                    {state.name}, your email has been verified!
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row pt-3 pb-1">
                                            <div className="col-lg-12 text-center">
                                                <Typography className="mt-3" variant="body2">
                                                    Your account has been successfully activated and you can log in with your email and password.
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row pt-4">
                                            <div className="col-lg-12 text-center">
                                                <Button className={classes.buttonStyle} href="/login">LOGIN</Button>
                                            </div>
                                        </div>
                                        <div className="row pt-4 pb-3">
                                            <div className="col-lg-12 text-center">
                                                <Typography className="mt-3" variant="body2">
                                                    You will be redirected in <Countdown
                                                        date={Date.now() + 10500}
                                                        intervalDelay={0}
                                                        precision={0}
                                                        renderer={renderer}
                                                        onComplete={redirect}
                                                    /> seconds.
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>      
                </div>
            </section>
            }
            {state.notFound &&
                <PageNotFound />
            }
        </React.Fragment>
    )
}

export default VerifyEmail;