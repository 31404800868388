import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import ManageModal from './manageModal';
import { reqQuickVerify } from '../../functions/requests/users';
import { redirectLogin, userStates } from '../../functions/global';
import { reqLoadUsers } from '../../functions/requests/users';

const ManageUsers = () => {
    const [state, setState] = useState({    
        access: false,
        disabledRequest: false,
        userResults: [],
        userRowData: []
    });

    const [editModal, setEditModal] = useState(false);

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(resQuickVerify.data.admin){
                    setState(prevState => ({ ...prevState, access: true }));
                    loadData();
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const loadData = () => {
        (async () => {
            try{
                const resLoadUsers = await reqLoadUsers();
                resLoadUsers.data.users.map(user => {
                    user.stateId = user.state;
                    user.state = userStates(user.state);
                    user.profile = user.userProfile.name;
                    return null;
                });
                setState(prevState => ({ ...prevState, userResults: resLoadUsers.data.users })); 
            }catch(error){
                setState(prevState => ({ ...prevState, userResults: [] }));
            }
        })();
    };

    const handleOpenEditModal = (userRowData) => {
        setState(prevState => ({ ...prevState, userRowData: userRowData }));
        setEditModal(!editModal);
    };

    const handleCloseEditModal = () => {
        setEditModal(!editModal);
    };

    const columnsUserResults = [
        { field: 'userName', title: 'Name' },
        { field: 'email', title: 'Email' },
        { field: 'state', title: 'State' },
        { field: 'profile', title: 'Profile' },
        { field: 'foods', title: 'Foods uploaded' },
    ];

    return(
        <React.Fragment>
            {state.access &&
                <React.Fragment>
                    <section>
                        <div className="container">
                            <div className="col-lg-12 py-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Card variant="outlined">
                                            <CardContent>
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Typography variant="h4">
                                                            Manage users
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row pb-3">
                                                    <div className="col-lg-12 mt-4 text-center tableSearchUsersContainer">
                                                        <MaterialTable
                                                            columns={columnsUserResults}
                                                            data={state.userResults}
                                                            actions={[
                                                                {
                                                                    icon: 'edit',
                                                                    tooltip: 'Edit User',                                                    
                                                                    onClick: (event, userRowData) => handleOpenEditModal(userRowData)
                                                                },
                                                            ]}
                                                            options={{
                                                                actionsColumnIndex: -1,
                                                                showTitle: false,
                                                                pageSize: 5,
                                                                search: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>                                           
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </section>
                    <ManageModal modal={editModal} handleCloseEditModal={handleCloseEditModal} userRowData={state.userRowData} loadData={loadData} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ManageUsers;