import Cookies from 'universal-cookie';

export function redirectLogin(){
    const cookies = new Cookies();
    cookies.remove('_fc'); 
    window.location.replace('/login');
}
export function convertMacronutrient(value){
    return parseInt(value*10000);
}
export function convertToFixed(num, fixed){
    if(num===0 || !num){
        return 0;
    }else{
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        var numFixed = num.toString().match(re)[0];
        if(numFixed % 1 === 0){
            return parseInt(numFixed);
        }else{
            return numFixed;
        }
    }
}
export function handleError(setState){
    setState(prevState => ({ 
        ...prevState, 
        requestSuccess: false, 
        requestError: true, 
        requestErrorMessage: 'Oops! Something went wrong. Please try again later.', 
        disabledRequest: false 
    }));
    removeMessages(setState, new URL(window.location.href).pathname);
}
export function removeMessages(setState, path){
    setTimeout(() => {
        if(path === (new URL(window.location.href).pathname)){
            setState(prevState => ({ ...prevState, requestSuccess: false, requestError: false }));
        }
    }, 7000);
}
export function disableRequest(setState){
    setState(prevState => ({ ...prevState, disabledRequest: true }));
}
export function foodStates(visibility){
    const states = ['Disabled', 'Public', 'Private', 'Shared'];
    return states[visibility];
}
export function userStates(visibility){
    const states = ['Created', 'Active', 'Blocked', 'Deleted'];
    return states[visibility];
}