import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Confirm from '../confirm/confirm';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import validateUserState from '../../functions/validations/validateUserState';
import { disableRequest, handleError, removeMessages } from '../../functions/global';
import { reqUpdateUserState } from '../../functions/requests/users';

const ManageModal = ({ modal, handleCloseEditModal, userRowData, loadData }) => {
    const [state, setState] = useState({
        id: "",
        state: "",
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false,
        errors: {}
    });

    const [showStateUpdateConfirm, setShowStateUpdateConfirm] = useState(false);

    useEffect(() => {
        setState(prevState => ({ ...prevState, 
            id: userRowData.id,
            state: userRowData.stateId }));
    }, [userRowData, modal]);

    const handleSubmitEditUser = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateUserState(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            setShowStateUpdateConfirm(!showStateUpdateConfirm);
        }
    }

    const handleUpdateState = (confirm) => {
        if(confirm){
            (async () => {
                try{
                    const resUpdateUserState = await reqUpdateUserState(state.id, state.state);
                    if(resUpdateUserState.data.success){
                        setState(prevState => ({ ...prevState,
                            requestSuccess: true, 
                            requestSuccessMessage: 'User state successfully updated.', 
                            disabledRequest: false,
                            requestError: false
                        }));
                        loadData();
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else if(resUpdateUserState.data.adminUpdate){
                        setState(prevState => ({ 
                            ...prevState, 
                            requestSuccess: false, 
                            requestError: true, 
                            requestErrorMessage: resUpdateUserState.data.message, 
                            disabledRequest: false 
                        }));
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    //error
                }
            })();
        }
        setState(prevState => ({ ...prevState, disabledRequest: false })); 
        setShowStateUpdateConfirm(!showStateUpdateConfirm);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));        
    }

    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            [theme.breakpoints.between('xs', 'sm')]: { width: '90%' },
            [theme.breakpoints.between('md', 'xl')]: { width: '60%' }
        },
        inputModalStyle: {
            width: '100%'
        },  
        modal: {
            position: 'absolute',
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 50,
            borderRadius: '4px'
        },
        buttonStyle: {
            width: '130px',
            height: '40px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        closeButtonStyle: {
            width: '130px',
            height: '40px',
            backgroundColor: "#d6d6d6",
            '&:hover': {
                backgroundColor: "#d6d6d6",
            },  
        },
        select: {
            textAlign: 'left',
            width: '100%'
        }
    }));

    const classes = useStyles();

    return (
        <React.Fragment>
            <Modal 
                open={modal}
                onClose={handleCloseEditModal}
                >         
                <div className={classes.modal}>
                    <h5 className="text-center" id="simple-modal-title">Edit user state</h5>
                    {state.requestError &&
                        <div className="row pt-3">
                            <div className="col-lg-12 text-center">
                                <Alert severity="error">{state.requestErrorMessage}</Alert>
                            </div>
                        </div>
                    }
                    {state.requestSuccess &&
                        <div className="row pt-3">
                            <div className="col-lg-12 text-center">
                                <Alert severity="success">{state.requestSuccessMessage}</Alert>
                            </div>
                        </div>
                    }
                    <form onSubmit={(e) => handleSubmitEditUser(e)}>
                        <div className="row pt-2">
                            <div className="col-lg-12 text-center">
                                <div className="row pb-2">
                                    <div className="col-lg-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-12 text-center">
                                            <FormControl className={classes.select}>
                                                <InputLabel id="stateLabel">State</InputLabel>
                                                <Select
                                                    labelId="stateLabel"
                                                    id="state"
                                                    name="state"
                                                    value={state.state}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <MenuItem value="0">Created</MenuItem>
                                                    <MenuItem value="1">Active</MenuItem>
                                                    <MenuItem value="2">Blocked</MenuItem>
                                                    <MenuItem value="3">Deleted</MenuItem>
                                                </Select>
                                            </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-6 text-center">
                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>EDIT USER</Button>
                                    </div>
                                    <div className="col-6 text-center">
                                        <Button className={classes.closeButtonStyle} onClick={() => handleCloseEditModal()}>CLOSE</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Confirm show={showStateUpdateConfirm} title="Are you sure you want to change the state of this user?" handleCloseConfirm={handleUpdateState} />           
        </React.Fragment>
    )
}

export default ManageModal;
