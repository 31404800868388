import { isInteger, isIntegerOrDecimal, hasValidDecimals, isGreaterThanZero } from '../validations';

const validateFood = (values) => {
    const errors = {};
    if(!values.visibility){
        errors.visibility = 'Please enter visibility';
    }
    if(!hasValidDecimals(values.fats, 1)){
        errors.fats = 'Maximum one decimal';
    }
    if(!isIntegerOrDecimal(values.fats) || !isGreaterThanZero(values.fats)){
        errors.fats = 'Please enter a valid fats value';
    }
    if(!values.fats){
        errors.fats = 'Please enter fats';
    }
    if(!hasValidDecimals(values.protein, 1)){
        errors.protein = 'Maximum one decimal';
    }
    if(!isIntegerOrDecimal(values.protein) || !isGreaterThanZero(values.protein)){
        errors.protein = 'Please enter a valid protein value';
    }
    if(!values.protein){
        errors.protein = 'Please enter protein';
    }
    if(!hasValidDecimals(values.carbs, 1)){
        errors.carbs = 'Maximum one decimal';
    }
    if(!isIntegerOrDecimal(values.carbs) || !isGreaterThanZero(values.carbs)){
        errors.carbs = 'Please enter a valid carbs value';
    }
    if(!values.carbs){
        errors.carbs = 'Please enter carbs';
    }
    if(!isInteger(values.calories)){
        errors.calories = 'Please enter a valid calories value';
    }
    if(!values.calories){
        errors.calories = 'Please enter calories';
    }
    if(!values.portion){
        errors.portion = 'Please enter a portion';
    }
    if(!values.name){
        errors.name = 'Please enter a name';
    }
    return errors
}

export default validateFood;