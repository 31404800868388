import { isIntegerOrDecimal, hasValidDecimals, isServing } from '../validations';

const validateAddServings = (values) => {
    const errors = {};

    if(!isServing(values.servings)){
        errors.servings = 'Please enter a valid serving value';
    }
    if(!hasValidDecimals(values.servings, 2)){
        errors.servings = 'Maximum two decimals';
    }
    if(!isIntegerOrDecimal(values.servings)){
        errors.servings = 'Please enter a valid serving value';
    }
    if(!values.servings){
        errors.servings = 'Please enter servings';
    }
    return errors
}

export default validateAddServings;