import { isEmail } from '../validations';

const validateForgotPassword = (values) => {
    const errors = {};

    if(!isEmail(values.email)){
        errors.email = 'Please enter a valid email';
    }
    if(!values.email){
        errors.email = 'Please enter an email';
    } 
    return errors
}

export default validateForgotPassword;