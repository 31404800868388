import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import validateAddServings from '../../functions/validations/validateAddServings';
import { reqSaveRecord } from '../../functions/requests/records';
import { handleError, removeMessages, disableRequest } from '../../functions/global';

const ServingsModal = ({ modal, handleCloseServingsModal, searchRowData, updateServingsDatatable, sumsTotals, classes }) => {
    const [state, setState] = useState({
        servings: "",
        disabledRequest: false,
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        errors: {}
    });

    const handleSubmitAddServings = (e) => {
        e.preventDefault();
        const values = { servings: state.servings };
        const results = validateAddServings(values);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    searchRowData.idFood = searchRowData.id;
                    const resSaveRecord = await reqSaveRecord(parseFloat(state.servings), searchRowData.idFood, searchRowData.calories, searchRowData.carbs, searchRowData.fats, searchRowData.protein);
                    if(resSaveRecord.data.success){
                        setState(prevState => ({ ...prevState, 
                            servings: "",
                            requestSuccess: true, 
                            requestSuccessMessage: 'Serving successfully added.', 
                            disabledRequest: false  }));
                        updateServingsDatatable(resSaveRecord.data.diaryResults.diaryResults);
                        sumsTotals(searchRowData.calories, searchRowData.carbs, searchRowData.fats, searchRowData.protein, parseFloat(state.servings));
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    handleError(setState);
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));        
    }

    const handleCloseCleanServings = () => {
        setState(prevState => ({ ...prevState, servings: "", requestSuccess: false, requestError: false }));
        handleCloseServingsModal();
    }

    return (
        <Modal 
            open={modal}
            onClose={handleCloseCleanServings}
            >         
            <div className={classes.modal}>
                <h5 className="text-center" id="simple-modal-title">Add servings to daily intake</h5>
                {state.requestError &&
                    <div className="row pt-3">
                        <div className="col-lg-12 text-center">
                            <Alert severity="error">{state.requestErrorMessage}</Alert>
                        </div>
                    </div>
                }
                {state.requestSuccess &&
                    <div className="row pt-3">
                        <div className="col-lg-12 text-center">
                            <Alert severity="success">{state.requestSuccessMessage}</Alert>
                        </div>
                    </div>
                }
                <form onSubmit={(e) => handleSubmitAddServings(e)}>
                    <div className="row pt-2">
                        <div className="col-lg-12 text-center">
                            <div className="row pb-2">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="servings" label="Servings (0.1 to 10)" value={state.servings} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.servings && 
                                                <small className="errorSmall">{state.errors.servings}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-6 text-center">
                                    <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>ADD SERVINGS</Button>
                                </div>
                                <div className="col-6 text-center">
                                    <Button className={classes.closeButtonStyle} onClick={() => handleCloseCleanServings()}>CLOSE</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ServingsModal;
