import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import ManageModal from './manageModal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Confirm from '../confirm/confirm';
import { redirectLogin, foodStates, disableRequest } from '../../functions/global';
import { reqQuickVerify } from '../../functions/requests/users';
import { reqLoadFoodByVisibility, reqUpdateFoodState } from '../../functions/requests/foods';

const ManageFood = () => {
    const [state, setState] = useState({    
        access: false,
        disabledRequest: false,
        foodResults: [],
        foodRowData: []
    });

    const [editModal, setEditModal] = useState(false);
    const [visibility, setVisibility] = useState({ state: "1", name: "PUBLIC" });
    const [showStateUpdateConfirm, setShowStateUpdateConfirm] = useState(false);

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(resQuickVerify.data.admin){
                    setState(prevState => ({ ...prevState, access: true }));
                    loadData(visibility.state);
                }else{
                    redirectLogin();
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        buttonStyle: {
            width: '180px',
            height: '40px',
            color: "#fff",
            '&:disabled': {
                color: "#fff",
            },
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            }
        }
    }));

    const columnsFoodResults = [
        { field: 'name', title: 'Food/Meal' },
        { field: 'portion', title: 'Portion' },
        { field: 'calories', title: 'Calories' },
        { field: 'carbs', title: 'Carbs' },
        { field: 'protein', title: 'Protein' },
        { field: 'fats', title: 'Fat' },
        { field: 'addedBy', title: 'Added by' }
    ];

    const handleOpenEditModal = (rowData) => {
        setState(prevState => ({ ...prevState, foodRowData: rowData }));
        setEditModal(!editModal);
    };

    const handleCloseEditModal = () => {
        setEditModal(!editModal);
    };

    const loadData = (visibility) => {
        (async () => {
            try{
                const resLoadFoodByVisibility = await reqLoadFoodByVisibility(visibility);
                resLoadFoodByVisibility.data.foodList.forEach(item => {
                    item.addedBy = item.user.name + ' ' + item.user.surname;
                    item.state = foodStates(item.visibility);
                });
                setState(prevState => ({ ...prevState, foodResults: resLoadFoodByVisibility.data.foodList })); 
            }catch(error){
                //error
            }
        })();
    };

    const handleVisibilityChange = (e) => {
        const currentChange = (e.target.value == 0) ? {state: '0', name: 'DISABLED'} : 
            (e.target.value == 1) ? {state: '1', name: 'PUBLIC'} : 
                (e.target.value == 2) ? {state: '2', name: 'PRIVATE'} : 
                    {state: '3', name: 'SHARED'};
        setVisibility(currentChange);
        loadData(e.target.value);
    };

    const handleUpdateStateConfirm = () => {
        setShowStateUpdateConfirm(!showStateUpdateConfirm);
    };

    const handleUpdateState = (confirm) => {
        if(confirm){
            disableRequest(setState);
            (async () => {
                try{
                    const resUpdateFoodState = await reqUpdateFoodState(visibility.state);
                    if(resUpdateFoodState.data.success){
                        loadData(visibility.state);
                    }
                }catch(error){
                    //error
                }
            })();
        }
        setState(prevState => ({ ...prevState, disabledRequest: false })); 
        setShowStateUpdateConfirm(!showStateUpdateConfirm);
    }

    const GreenRadio = withStyles({
        root: {
            color: '#37a37c',
            '&checked': {
                color: '#37a37c',
            },
        }
    })((props) => <Radio color="default" {...props} />);

    const classes = useStyles();

    return(
        <React.Fragment>
            {state.access &&
                <React.Fragment>
                    <section>
                        <div className="container">
                            <div className="col-lg-12 py-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Card variant="outlined">
                                            <CardContent>
                                                <div className="row pt-4">
                                                    <div className="col-lg-12 text-center">
                                                        <Typography variant="h4">
                                                            Manage food
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8 col-xs-12 justify-content-center d-flex mt-4">    
                                                        <FormControl component="fieldset">
                                                            <RadioGroup aria-label="visibility" name="visibility" value={visibility.state} onChange={(e) => handleVisibilityChange(e)}>
                                                                <div className="row">
                                                                    <div className="col-lg-3 row-50-mobile px-0">
                                                                        <FormControlLabel className="my-0 ml-0" value="1" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(visibility.state == '1')} />} label="Public" />
                                                                    </div>
                                                                    <div className="col-lg-3 row-50-mobile px-0">
                                                                        <FormControlLabel className="my-0 ml-0" value="3" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(visibility.state == '3')} />} label="Shared" />
                                                                    </div>
                                                                    <div className="col-lg-3 row-50-mobile px-0">
                                                                        <FormControlLabel className="my-0 ml-0" value="2" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(visibility.state == '2')} />} label="Private" />
                                                                    </div>
                                                                    <div className="col-lg-3 row-50-mobile px-0">
                                                                        <FormControlLabel className="my-0 ml-0" value="0" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(visibility.state == '0')} />} label="Disabled" />
                                                                    </div>
                                                                </div>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-lg-4 col-xs-12 justify-content-center d-flex mt-4">    
                                                        <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false} onClick={() => handleUpdateStateConfirm()}>SET ALL AS {visibility.name}</Button>
                                                    </div>
                                                </div>
                                                <div className="row py-3">
                                                    <div className="col-lg-12 text-center tableAllFoodContainer">
                                                        <MaterialTable
                                                            columns={columnsFoodResults}
                                                            data={state.foodResults}
                                                            actions={[
                                                                {
                                                                icon: 'edit',
                                                                tooltip: 'Edit Food',                                                    
                                                                onClick: (event, rowData) => handleOpenEditModal(rowData)
                                                                }
                                                            ]}
                                                            options={{
                                                                actionsColumnIndex: -1,
                                                                showTitle: false,
                                                                pageSize: 5
                                                            }}
                                                        />
                                                    </div>
                                                </div>                                              
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </section>
                    <Confirm show={showStateUpdateConfirm} title="Are you sure you want to change the visibility of all items?" handleCloseConfirm={handleUpdateState} />
                    <ManageModal modal={editModal} handleCloseEditModal={handleCloseEditModal} foodRowData={state.foodRowData} loadData={loadData} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ManageFood;