import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const cookies = new Cookies();
    const auth = (cookies.get('_fc')) ? true : false;
    
    return (
        <Route {...rest} render={props => (
            (auth && restricted) ? ( <Redirect to="/dailydiet" />
                ): <Component {...props} />
        )} />
    );
}

export default PublicRoute;