import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../../config/config.json';

export async function reqLoadRecords(){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'records/loadRecords'
    });
    return request;
};

export async function reqSaveRecord(servings, foodId, calories, carbs, fats, protein){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('servings', servings);
    params.append('foodId', foodId);
    params.append('calories', calories);
    params.append('carbs', carbs);
    params.append('fats', fats);
    params.append('protein', protein);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'records/saveRecord',
        data: params
    });
    return request;
};

export async function reqRemoveRecord(recordId, servings, calories, carbs, fats, protein){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('recordId', recordId);
    params.append('servings', servings);
    params.append('calories', calories);
    params.append('carbs', carbs);
    params.append('fats', fats);
    params.append('protein', protein);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'records/removeRecord',
        data: params
    });
    return request;
};

export async function reqUpdateRecord(recordId, servings, oldServings, calories, carbs, fats, protein){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('recordId', recordId);
    params.append('servings', servings);
    params.append('oldServings', oldServings);
    params.append('calories', calories);
    params.append('carbs', carbs);
    params.append('fats', fats);
    params.append('protein', protein);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'records/updateRecord',
        data: params
    });
    return request;
};