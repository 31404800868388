import React, { useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import EqualizerTwoToneIcon from '@material-ui/icons/EqualizerTwoTone';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { isMobileOnly } from "react-device-detect";
import { reqQuickVerify } from '../../functions/requests/users';
import { redirectLogin } from '../../functions/global';

const Sidebar = ({ theme, open, setOpen, drawerWidth, setShowToolbarContent }) => {
    const [access, setAccess] = useState(false);
    const [foodLinks] = useState(['dailydiet', 'calendar', 'addfood']);
    const [accountLinks, setAccountLinks] = useState(['myaccount', 'mypassword']);
    const [foodMenu] = useState(['Daily Diet', 'Calendar', 'Add Food']);
    const [accountMenu, setAccountMenu] = useState(['My Account', 'My Password']);

    useEffect(() => {
        (async () => {
            try{
                const resQuickVerify = await reqQuickVerify();
                if(resQuickVerify.data.success){
                    setAccess(true);
                }else{
                    redirectLogin();
                }
                if(resQuickVerify.data.admin){
                    setAccountLinks(newAccountLinks => [...newAccountLinks, 'managefood', 'manageusers', 'statistics']);
                    setAccountMenu(newAccountMenu => [...newAccountMenu, 'Manage Food', 'Manage Users', 'Statistics']);
                }
            }catch(error){
                redirectLogin();
            }
        })();
    }, []);

    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
    }));

    const handleDrawerClose = () => {
        setOpen(false);
        if(isMobileOnly){
            setShowToolbarContent(true);
        }
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            {access &&
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                    {foodMenu.map((text, index) => (
                        <ListItem button key={text} to={"/"+foodLinks[index]} component={Link} onClick={handleDrawerClose}>
                        <ListItemIcon>
                        {(index === 0) ? ( <QueryBuilderIcon /> 
                            ): (index === 1) ? ( <DateRangeIcon /> 
                                ): (index === 2) ? ( <FastfoodIcon /> 
                                    ) : <AddIcon />
                        }
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        </ListItem>
                    ))}
                    </List>
                    <Divider />
                    <List>
                    {accountMenu.map((text, index) => (
                        <ListItem button key={text} to={"/"+accountLinks[index]} component={Link} onClick={handleDrawerClose}>
                        <ListItemIcon>
                        {(index === 0) ? ( <PersonIcon /> 
                            ): (index === 1) ? (<VpnKeyIcon /> 
                            ): (index === 4) ? (<EqualizerTwoToneIcon /> 
                            ): <SettingsIcon />
                        }                        
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        </ListItem>
                    ))}
                    </List>
                </Drawer>
            }
        </React.Fragment>
    )
}

export default Sidebar
