import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../../config/config.json';

export async function reqSaveFood(foodState){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('name', foodState.name.trim());
    params.append('portion', foodState.portion.trim());
    params.append('calories', foodState.calories);
    params.append('carbs', foodState.carbs);
    params.append('protein', foodState.protein);
    params.append('fats', foodState.fats);
    params.append('visibility', foodState.visibility);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/saveFood',
        data: params
    });
    return request;
};

export async function reqFoodSearch(foodSearch){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('foodSearch', foodSearch);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/foodSearch',
        data: params
    });
    return request;
};

export async function reqFoodFileUpload(file){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    var formData = new FormData();
    formData.append('file', file);
    const request = await axios({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/foodFileUpload',
        data: formData
    });
    return request;
};

export async function reqLoadFoodByVisibility(visibility){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('visibility', visibility);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/loadFoodByVisibility',
        data: params
    });
    return request;
};

export async function reqUpdateFood(foodState){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('id', foodState.id);
    params.append('name', foodState.name.trim());
    params.append('portion', foodState.portion.trim());
    params.append('calories', foodState.calories);
    params.append('carbs', foodState.carbs);
    params.append('protein', foodState.protein);
    params.append('fats', foodState.fats);
    params.append('visibility', foodState.visibility);
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/updateFood',
        data: params
    });
    return request;
};

export async function reqUpdateFoodState(visibility){
    const cookies = new Cookies();
    const token = cookies.get('_fc');
    const params = new URLSearchParams();
    params.append('visibility', parseInt(visibility));
    const request = await axios({
        method: 'post',
        headers: { Authorization: 'Bearer '+token },
        url: config.CALORIAS_BACKEND+'foods/updateFoodState',
        data: params
    });
    return request;
};

