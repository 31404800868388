import React from 'react';
import { Route, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const PrivateRoute = ({component: Component, ...rest}) => {
    const cookies = new Cookies();
    const auth = (cookies.get('_fc')) ? true : false;

    return (
        <Route {...rest} render={props => (
            (auth) ? ( <Component {...props} />
                ): <div onLoad={window.location.replace('/login')}></div>
        )} />
    );
}

export default PrivateRoute;