import { isEmail, isName, passwordsMatch, isPassword, isNotOnlyWhitespace } from '../validations';

const validateSignUp = (values) => {
    const errors = {};
    if(!isName(values.surname) || !isNotOnlyWhitespace(values.surname)){
        errors.surname = 'Please enter a valid surname';
    }
    if(!values.surname){
        errors.surname = 'Please enter a surname';
    }
    if(!isName(values.name) || !isNotOnlyWhitespace(values.name)){
        errors.name = 'Please enter a valid name';
    }
    if(!values.name){
        errors.name = 'Please enter a name';
    }
    if(!passwordsMatch(values.password, values.repeatPassword)){
        errors.repeatPassword = 'Passwords must match';
    }
    if(!isPassword(values.password)){
        errors.password = 'Password must have at least 8 characters, 1 uppercase letter, 1 number and 1 special character';
    }
    if(!values.password){
        errors.password = 'Please enter a password';
    }
    if(!isEmail(values.email)){
        errors.email = 'Please enter a valid email';
    }
    if(!values.email){
        errors.email = 'Please enter an email';
    } 
    return errors
}

export default validateSignUp;