import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import validateFood from '../../functions/validations/validateFood';
import { disableRequest, handleError, removeMessages } from '../../functions/global';
import { reqUpdateFood } from '../../functions/requests/foods';

const ManageModal = ({ modal, handleCloseEditModal, foodRowData, loadData }) => {
    const [state, setState] = useState({
        id: "",
        name: "",
        portion: "",
        calories: "",
        carbs: "",
        protein: "",
        fats: "",
        visibility: "",
        requestError: false,
        requestErrorMessage: "",
        requestSuccess: false,
        requestSuccessMessage: "",
        disabledRequest: false,
        errors: {}
    });

    useEffect(() => {
        setState(prevState => ({ ...prevState, 
            id: foodRowData.id,
            name: foodRowData.name,
            portion: foodRowData.portion,
            calories: foodRowData.calories,
            carbs: foodRowData.carbs,
            protein: foodRowData.protein,
            fats: foodRowData.fats,
            visibility: foodRowData.visibility }));
    }, [foodRowData, modal]);

    const handleSubmitEditFood = (e) => {
        e.preventDefault();
        const { errors, ...noErrors } = state;
        const results = validateFood(noErrors);
        setState(prevState => ({ ...prevState, errors: results, requestError: false, requestErrorMessage: "", requestSuccess: false, requestSuccessMessage: "" }));
        if(Object.entries(results).length === 0){
            disableRequest(setState);
            (async () => {
                try{
                    const resUpdateFood = await reqUpdateFood(state);
                    if(resUpdateFood.data.success){
                        setState(prevState => ({ ...prevState,
                            requestSuccess: true, 
                            requestSuccessMessage: 'Food successfully updated.', 
                            disabledRequest: false,
                            requestError: false
                        }));
                        loadData(foodRowData.visibility);
                        removeMessages(setState, new URL(window.location.href).pathname);
                    }else{
                        handleError(setState);
                    }
                }catch(error){
                    
                }
            })();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name] : value
        }));        
    }

    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            [theme.breakpoints.between('xs', 'sm')]: { width: '90%' },
            [theme.breakpoints.between('md', 'xl')]: { width: '60%' }
        },
        inputModalStyle: {
            width: '100%'
        },  
        modal: {
            position: 'absolute',
            [theme.breakpoints.between('xs', 'sm')]: { width: 400 },
            [theme.breakpoints.between('md', 'xl')]: { width: 600 },
            backgroundColor: '#fff',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 50,
            borderRadius: '4px'
        },
        buttonStyle: {
            width: '130px',
            height: '40px',
            color: "#fff",            
            backgroundColor: "#37a37c",
            '&:hover': {
                backgroundColor: "#37a37c",
            },
        },
        closeButtonStyle: {
            width: '130px',
            height: '40px',
            backgroundColor: "#d6d6d6",
            '&:hover': {
                backgroundColor: "#d6d6d6",
            },  
        }
    }));

    const GreenRadio = withStyles({
        root: {
            color: '#37a37c',
            '&checked': {
                color: '#37a37c',
            },
        }
    })((props) => <Radio color="default" {...props} />);

    const classes = useStyles();

    return (
        <Modal 
            open={modal}
            onClose={handleCloseEditModal}
            >         
            <div className={classes.modal}>
                <h5 className="text-center" id="simple-modal-title">Edit food</h5>
                {state.requestError &&
                    <div className="row pt-3">
                        <div className="col-lg-12 text-center">
                            <Alert severity="error">{state.requestErrorMessage}</Alert>
                        </div>
                    </div>
                }
                {state.requestSuccess &&
                    <div className="row pt-3">
                        <div className="col-lg-12 text-center">
                            <Alert severity="success">{state.requestSuccessMessage}</Alert>
                        </div>
                    </div>
                }
                <form onSubmit={(e) => handleSubmitEditFood(e)}>
                    <div className="row pt-2">
                        <div className="col-lg-12 text-center">
                            <div className="row pb-2">
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="name" label="Name" value={state.name} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.name && 
                                                <small className="errorSmall">{state.errors.name}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="portion" label="Portion" value={state.portion} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.portion && 
                                                <small className="errorSmall">{state.errors.portion}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="calories" label="Calories" value={state.calories} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.calories && 
                                                <small className="errorSmall">{state.errors.calories}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="carbs" label="Carbs" value={state.carbs} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.carbs && 
                                                <small className="errorSmall">{state.errors.carbs}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="protein" label="Protein" value={state.protein} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.protein && 
                                                <small className="errorSmall">{state.errors.protein}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <TextField className={classes.inputModalStyle} onChange={(e) => handleChange(e)} name="fats" label="Fats" value={state.fats} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {state.errors.fats && 
                                                <small className="errorSmall">{state.errors.fats}</small>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-1 mt-4">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-left">    
                                            <FormControl component="fieldset">
                                                <FormLabel className="grayMediumLabel mb-1" component="legend">Visibility</FormLabel>
                                                <RadioGroup aria-label="visibility" name="visibility" value={state.visibility} onChange={(e) => handleChange(e)}>
                                                    <div className="row">
                                                        <div className="col-6 px-0">
                                                            <FormControlLabel className="my-0 ml-0" value="1" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(state.visibility == 1)} />} label="Public" />
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <FormControlLabel className="my-0 ml-0" value="3" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(state.visibility == 3)} />} label="Shared" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 px-0">
                                                            <FormControlLabel className="my-0 ml-0" value="2" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(state.visibility == 2)} />} label="Private" />
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <FormControlLabel className="my-0 ml-0" value="0" labelPlacement="end" color="primary" control={<GreenRadio className="my-0" checked={(state.visibility == 0)} />} label="Disabled" />
                                                        </div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>                 
                            <div className="row pt-4">
                                <div className="col-6 text-center">
                                    <Button type="submit" className={classes.buttonStyle} disabled={state.disabledRequest?true:false}>EDIT FOOD</Button>
                                </div>
                                <div className="col-6 text-center">
                                    <Button className={classes.closeButtonStyle} onClick={() => handleCloseEditModal()}>CLOSE</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ManageModal;
